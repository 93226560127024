@import "src/colors";
.search-highlighted-count{
  display: flex;
  align-items: center;

  &_numbers{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $textTertiary;
  }

  &_divider{
    width: 1px;
    background: $divider;
    margin: 0 12px;
    height: 16px;
  }
  &_arrows{
    cursor: pointer;
    grid-gap: 12px;
    display: flex;
    align-items: center;
  }
}


@mixin dnd-drop-edge{
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 100;
}
.dnd-drop-edge-start-area {
  @include dnd-drop-edge;
  left: 0
}
.dnd-drop-edge-end-area {
  @include dnd-drop-edge;
  right: 0
}


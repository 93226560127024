@import "src/colors";

.column-resizer{
  position: absolute;
  height: 100%;
  z-index: 100;
  cursor: col-resize;
  &.start{
    left: 0;
  }
  &.end{
    right: 0;
  }
}

.column-resizer-indicator{
  cursor: col-resize;
  height: 100%;
  position: absolute;
  width: 1px;
  left: -1px;
  z-index: 1000;
  background: $accentMagenta;
}

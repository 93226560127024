@import "src/colors";

.virtual-table-cell {
  box-sizing: border-box;
  padding: 12px;
  border-bottom: 1px solid $divider;
  background: $backgroundWhite;
  overflow: hidden;
  text-overflow: ellipsis;
  border-left: 1px solid $divider;
  position: relative;
  white-space: nowrap;

  border-right: 1px solid transparent;

  &_aligh{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &.even{
    background-color: $tableBackgroundBlue
  }
  &.selected{
    background-color: rgba($accentMagenta, 0.1);
  }

  &.first {
    border-left: none;
  }

  &.drop-active {
    border-right: 1px solid $accentMagenta;
  }

  &.drag-overlay {
    &::after {
      display: block;
      content: '';
      position: absolute;
      background: $divider;
      left: 8px;
      right: 8px;
      top: 8px;
      bottom: 8px;
    }

    * {
      opacity: 0;
    }
  }

  &.drop-area-start-edge{
    border-left: 1px solid $accentMagenta;
  }

  &.drop-area-end-edge{
    border-right: 1px solid $accentMagenta;
  }
}

@import "src/colors";

.selectable-cell {
  padding: 12px;
  border-bottom: 1px solid $divider;
  background: $backgroundWhite;
  display: flex;
  align-items: center;
  justify-content: center;
  &.first {
    border-left: none;
  }

  &.even{
    background-color: $tableBackgroundBlue
  }
  &.selected{
    background-color: rgba($accentMagenta, 0.1);
  }
}

@import 'src/colors';

.lab-experiment-progress-workspace {
	position: relative;
	padding-top: 84px;
	.view-mode-wrapper {
		overflow-x: auto;
		.manual-components-item {
			margin-left: 30px;
		}
	}
	.lab-experiment-progress-title {
		position: absolute;
		color: $textPrimary;
		font-weight: bold;
		font-size: 20px;
		left: 44px;
		top: 32px;
	}
}
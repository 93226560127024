@import "src/colors";
.header-cell{
  cursor: grab;
  padding: 12px;
  border-bottom: 2px solid $divider;
  border-left: 1px solid $divider;
  background-color: $backgroundWhite;
  display: flex;
  align-items: center;
  width: 100%;

  &.drop-area-start-edge{
    border-left: 1px solid $accentMagenta;
  }

  &.drop-area-end-edge{
    border-right: 1px solid $accentMagenta;
  }

  &_title{
    max-width: calc(100% - 30px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $textSecondary;
  }

  &.is-first{
    border-left: none;
  }

  &_sort-icon{
    margin-left: 6px;
    padding: 6px;
    transition: 0.3s ease-in;
    border-radius: 4px;
    &:hover{
      cursor: pointer;
      background-color: $tableBackgroundBlue;
    }
    &.ascend{
      svg path:last-of-type{
        fill-opacity: 1;
        fill: $accentMagenta;
      }
    }
    &.descend{
      svg path:first-of-type{
        fill-opacity: 1;
        fill: $accentMagenta;
      }
    }

  }
}

.dnd-drag-box {
  &.active-drop {
    border-right: 1px solid $accentMagenta;
  }
}

@import "src/colors";
.selectable-header-cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.selectable-header-cell-checkbox {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

//primary

$primaryLightBlue: #46c5e9;
$primaryDarkBlue: #2c4652;

//accent
$accentMagenta: #e00087;
$accentMagentaTint: #ed008f;
$accentMagentaShade: #c90079;
$accentMagentaDisabled: #e0b4ce;
$accentLightHover: #fce6f3;

//secondary
$alertYellow: #f8d33b;
$errorRed: #e4002b;
$successGreen: #20a89a;

//text
$textPrimary: #2c4652;
$textSecondary: rgba(44, 70, 82, 0.5);
$textTertiary: rgba(44, 70, 82, 0.3);

//lines
$divider: rgba(44, 70, 82, 0.1);
$inputBorder: rgba(44, 70, 82, 0.2);
$builderLine: #91a0a7;

//background
$pageBackgroundBlue: #f5fbfc;
$pageBackgroundDarkBlue: rgba(70, 197, 233, 0.03);
$pageBackgroundGray: #f2f2f2;
$backgroundWhite: #ffffff;
$backgroundLightBlue: rgba(70, 197, 233, 0.1);
$backgroundLightOrange: rgba(228, 0, 43, 0.1);
$tableBackgroundBlue: rgba(24, 70, 91, 0.04);

//shadow
$shadow: #2c4652;

//reactor
$reactorIconBackground: #20a89a;

$purple: #9b51e0;
$orange: #f2994a;
$blue: #2f80ed;
$red: #eb5757;
$black: #000;

/**
 * Variables used in scss
 * Access it in styled-components by using theme.  (ex. ${props => $props.theme.<variable below> })
 */

@import 'src/colors';

// sizes
$sizeXsmall: 5px;
$sizeSmall: 15px;
$sizeMedium: 30px;
$sizeLarge: 50px;

$headerSize: 75px;
$headerLargeSize: 150px;
$footerSize: 75px;
$contentSize: 1200px;

$borderRadius: 4px;

// New color standards
$debugColor: #ff0000;
$primaryColor: #191d1e;
$secondaryColor: #808080;
$tertiaryColor: #2c4652; // darkBlue
$edgeColor: #979797;
$placeholderColor: #9b9b9b;

$spotColor: #46c5e9; // lightBlue
$saveColor: #f8d33b; // yellow
$flagColor: #e40d2b; // red
$keepColor: #284451; //
$primaryBgColor: #ffffff;
$secondaryBgColor: #e7e7e7;
$tertiaryBgColor: #000000;
$popupBgColor: #00000055;
$popupAltBgColor: #ffffff77;

// New size standards
$largeBodyFontSize: 18px;
$primaryBodyFontSize: 16px;
$smallBodyFontSize: 12px;
$primaryButtonFontSize: 12px;

// Button color
$primaryButtonColor: #2c4652; // darkBlue
$primaryButtonHoverColor: #46c5e9; // lightBlue
$disabledButtonColor: #cccccc; // edgeColor 979797
$disabledButtonHoverColor: #cccccc; // edgeColor
$flagButtonHoverColor: #e40d2b; // red
$transparentButtonColor: transparent;
$saveButtonColor: #f8d33b; // TODO: yellow, to be removed?

.node {
  display: flex;
  position: absolute;
  border: 3px solid $spotColor;
  justify-content: center;
}

#BOM-buttons button,
.node button {
  background-color: $primaryBgColor;
  padding: 4px 8px;
  cursor: pointer;
  margin: 1px;
  color: $primaryButtonColor;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: $primaryButtonFontSize !important;
  white-space: nowrap;
  border: 2px solid $primaryButtonColor;

  &:hover {
    color: $spotColor;
    border-color: $spotColor;
    background-color: transparent;
  }
}

#BOM-buttons button {
  border: 0px;
}

#BOM-buttons button:focus,
.node button:focus {
  outline-style: none;
}

.node.keep {
  border: 3px dotted $keepColor;
}
.keep .btnKeep {
  color: $primaryBgColor;
  border-color: $keepColor;
  background-color: $keepColor;
}

.node.avoid {
  border: 3px dotted $flagColor;
}
.avoid .btnAvoid {
  color: $primaryBgColor;
  border-color: $flagColor;
  background-color: $flagColor;
}

.node.detailed {
  border: 3px solid $secondaryColor !important;
}

.footer {
  position: absolute;
  bottom: 0px;
}

.hide {
  display: none;
}

.nohide {
  display: flex !important;
}

/* temporary tooltip stuff */
/*  http://www.cssarrowplease.com/ */
.arrow_box_left {
  position: absolute;
  background: #a3a09e;
  border: 1px solid #000000;
  border-radius: 5px;
  z-index: 4;
  white-space: nowrap;
}

.arrow_box_left:after,
.arrow_box_left:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box_left:after {
  border-color: rgba(163, 160, 158, 0);
  border-right-color: #a3a09e;
  border-width: 8px;
  margin-top: -8px;
}
.arrow_box_left:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 9px;
  margin-top: -9px;
}

.tooltip-container {
  position: relative;
}

.tooltip {
  display: inline;
  position: absolute;
  z-index: 4;
}

.hidden {
  visibility: hidden;
}

#BOM-table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#BOM-table td,
#BOM-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#BOM-table tr:nth-child(even) {
  background-color: #f8f8f8;
}

#BOM-table tr:hover {
  background-color: #ddd;
}

#BOM-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e7e7e7;
  color: black;
}

#BOM-popup {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: white;
  border: 3px solid #a3a09e;
  border-radius: 5px;
  z-index: 100;
  display: none;
}

#BOM-buttons {
  float: right;
}

.canvas-container {
  width: 100%;
  height: 100%;
  position: relative;

  canvas {
    z-index: 5;
    position: relative;
  }

  &.empty {
    width: 88px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 21px;
      height: 21px;
      color: $textSecondary;
    }
  }

  &_steps {
    z-index: 1;
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: $pageBackgroundBlue;
    padding-bottom: 20px;

    font-size: 14px;
    color: $textSecondary;

    &_active {
      z-index: 4;

      background: $backgroundWhite;

      &_first {
        border-left: 1px solid $divider;
      }
      &_last {
        border-right: 1px solid $divider;
      }
    }
  }

  &_steps-all {
    border: none !important;
  }

  &-with-steps {
    padding-bottom: 30px;
  }
}

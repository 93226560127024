.type-to-search-select {

  .ant-select-arrow {
    user-select: initial;
    pointer-events: initial;
  }

  &_smiles-drawer {

  }
}

.container-select_smiles{
  border: none;
  height: initial;
  width: 100%;
  background: initial;

  .ant-select-item-option-content{
    text-align: left;
  }

  .ant-select-selector{
    padding-right: 20px !important;
  }

  .ant-select-selection-placeholder{
    right: initial !important;
    left: initial !important;
  }
}
@import '../../colors.scss';
@import '../../variables';

.login-wrapper {
  height: 100vh;
  display: flex;
  position: relative;
  .left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 44%;
    height: 100%;
    padding: 0 110px 0 108px;
    background-color: white;
    box-shadow: 0 1px 5px $divider;
    .sri-version {
      position: absolute;
      bottom: 40px;
      width: 100%;
      text-align: center;
      color: $textSecondary;
      font-size: 12px;
    }
    .login-form {
      width: 100%;
      min-width: 300px;
      max-width: 400px;
      .password {
        .ant-input-password {
          height: 34px;
        }
      }

      .login-title {
        /* text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: $textPrimary; */
        height: 36px;
        margin: 0 calc(50% - 75px) 32px 0;
      }

      .input-margin {
        margin-bottom: 20px;
      }

      .login-wrapper-btns {
        margin-top: 24px;

        .ant-btn {
          width: 100%;
          text-transform: uppercase;
        }

        .btn-okta {
          border-color: #007dc1;
          color: #007dc1;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin-right: 8px;
          }
        }

        .divider {
          margin: 27px 0 22px 0;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &-block {
            position: absolute;
            border: 1px solid $divider;
            width: 100%;
            top: 14px;
          }

          .pill-text {
            width: 40px;
            height: 28px;
            font-size: 12px;
            border-radius: 20px;
            background-color: $pageBackgroundGray;
            color: $textSecondary;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
          }
        }
      }

      .password span {
        margin-left: 0;
        color: $inputBorder;
      }
    }

    .sri-image {
      position: absolute;
      top: 10%;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: url('../../dist/images/login-page.svg') no-repeat center;
    background-size: cover;

    .login-text {
      font-weight: bold;
      font-size: 60px;
      line-height: 73px;
      color: white;
      text-shadow: 0 1px 5px rgba(44, 70, 82, 0.1), 0 1px 2px rgba(44, 70, 82, 0.1);
      margin-left: 100px;
      font-family: $fontFamily;
    }
  }
}

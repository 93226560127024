@import "src/colors";
.virtual-table-spinner {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .spinner-component.ant-spin {
    height: auto !important;
    width: auto !important;
    position: static !important;

    .spinner-subtitle {
      font-weight: 400;
      margin-top: 10px;
      line-height: 36px;
    }
  }

  .ant-spin-blur {
    z-index: -1;
  }

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 0;
  }

  .ant-skeleton-content .ant-skeleton-title {
    display: none;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 48px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 48px;
  }

  .skeleton-header-loader {
    display: flex;
    justify-content: space-between;
    padding: 17px 65px;
    background: $backgroundWhite;
    border-bottom: 2px solid $divider;

    .ant-skeleton-element {
      width: 100%;

      .ant-skeleton-input {
        filter: brightness(85%);
        border-radius: 4px;
      }
    }
  }
}

@import "src/colors";

.columns-sortable-select-all{
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 42px;

  .ant-checkbox{
    margin-right: 36px;
  }
  border-bottom: 1px solid $divider;

  .typography-body-12{
    color: $textSecondary;
  }
}

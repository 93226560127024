@import './colors';

.experiment-wrapper {
  margin: -24px;
  display: flex;
  min-height: calc(100vh - 60px);
  background-color: $backgroundWhite;

  &_left {
    width: 400px;
    padding: 24px;
    border-right: 1px solid $divider;
  }

  &_right {
    width: calc(100% - 400px);
  }
}

.reset-padding-container {
  margin: -24px;
}

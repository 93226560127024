.input-with-tags{
  position: relative;
  &_apply{
    padding: 12px;
    cursor: pointer;
  }
  .ant-popover.popover-overlay{
    width: 100%;
  }

  .ant-select-dropdown{
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
  }
}

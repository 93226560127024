@import '../../../../colors';

.synjet-products-list {
  display: flex;
  flex-direction: column;
  &__header {
    display: grid;
    grid-template-columns: 4fr 2fr 1.5fr 3.5fr 1fr;
    margin-bottom: 12px;
    margin-left: 70px;
    width: calc(100% - 145px);
    .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: rgba($textSecondary, 0.5);
    }
  }
  .combination-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    pointer-events: auto;
    .plus-icon {
      height: fit-content;
      color: #E00087;
      margin-right: 6px;
      margin-bottom: 7px;
    }
    .compound-item {
      margin-right: 8px;
      margin-bottom: 8px;
      width: 96px;
      background: #FFFFFF;
      border: 1px solid rgba(44, 70, 82, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      &.quenching {
        width: 56px
      }
      &__image {
        border-bottom: 1px dashed rgba(44, 70, 82, 0.2);
        &.solvent-image {
          height: 58px;
          padding: 16px 26px;
        }
      }
      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        padding: 6px;
        .name {
          font-size: 12px;
          line-height: 18px;
          color: #2C4652;
        }
        .icon {
          font-size: 10px;
          background-color: rgba(44, 70, 82, 0.2);
          border-radius: 50%;
          color: white;
          width: 14px;
          height: 14px;
          padding: 2px;
        }
      }
    }
    .show-hidden {
      border: 1px solid rgba(44, 70, 82, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      height: 80px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: rgba(44, 70, 82, 0.5);
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(70, 197, 233, 0.1);
    }
  }
}

.synjet-quenching {
  display: flex;
  flex-direction: column;
  &__header {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #2c4652;
  }
  &__list {
    display: flex;
    flex-direction: column;
    &__header {
      display: grid;
      grid-template-columns: 3fr 2fr 1.5fr 3fr;
      margin-left: 70px;
      width: calc(100% - 145px);
      margin-bottom: 10px;
      .title {
        font-weight: 500;
        font-size: 12px;
        color: rgba(44, 70, 82, 0.5);
      }
      .equivalent {
        width: 100%;
        display: grid;
        grid-column-gap: 8px;
        column-gap: 8px;
      }
    }
  }
  .add-btn {
    margin: 16px 0 0;
    width: fit-content;
    text-transform: capitalize;
  }
}

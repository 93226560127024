@import "src/colors";

.columns-sortable-list-item{
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 2px;
  cursor: pointer;

  &_checkbox {
    width: 100%;
  }

  &:hover{
    background: rgba($accentMagenta, 0.1);
    svg{
      path{
        fill: $accentMagenta;
      }
    }
  }
  .ant-checkbox{
    margin-right: 36px;
  }
}
